import { AbstractOffer } from "@core/store/logic/common/offer/AbstractOffer";
import { Advertisers } from "@core/logic/admin/users/users";
import { AdminOfferInterface } from "@core/store/types/admin/offer";
import { Money } from "@core/store/types/common";

export class AdminOffer extends AbstractOffer {
  public advertisers?: Array<Advertisers>;
  public webmasterReward?: Money;
  public advertiserMinimalWriteOff?: Money;
  private currency?: string;

  constructor (payload?: AdminOfferInterface) {
    super(payload);
    if (payload) {
      this.advertisers = payload.advertisers;
      this.webmasterReward = payload.finance?.webmasterReward;
      this.currency = payload.finance?.currency;
      this.advertiserMinimalWriteOff = payload.advertiserMinimalWriteOff;
    }
  }
}
