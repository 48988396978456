<template>
    <b-skeleton
        v-if="isLoading"
        width="10rem"
        height="35px"
        animated>
    </b-skeleton>
    <b-field v-else>
        <div
            :key="index"
            v-for="(currency, index) of currencyList"
            class="control">
            <b-button
                :value="currency"
                :native-value="currency"
                :type="getType(currency)"
                @click="update(currency)">
                <span>{{ $t(`common.currency.${ currency }`) }}</span>
            </b-button>
        </div>
    </b-field>
</template>

<script>
  import { mapState } from "vuex";
  import {
    GET_TOKEN,
    GET_WHO_AM_I
  } from "@core/store/action-constants";
  import _intersection from "lodash/intersection";

  export default {
    name: "CurrencyCheckbox",
    props: {
      value: {
        type: [Array, String],
        default: null
      },
      multiple: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: "is-info is-light"
      }
    },

    created () {
      if (!this.multiple) {
        this.selectedCurrencies = this.value ? [this.value] : [this.preferredCurrency];
        this.$emit("input", this.selectedCurrencies[0]);
      } else {
        this.selectedCurrencies = this.value || this.currencyList;
      }
    },

    data () {
      return {
        selectedCurrencies: []
      };
    },

    computed: {
      ...mapState({
        currencyList: ({ auth }) => auth.availableCurrencies,
        preferredCurrency: ({ auth }) => auth.preferredCurrency
      }),

      isLoading () {
        return this.$wait(GET_TOKEN) || this.$wait(GET_WHO_AM_I);
      }
    },

    methods: {
      getType (currency) {
        return this.isSelected(currency) ? this.type : null;
      },

      isSelected (currency) {
        return this.selectedCurrencies?.includes(currency);
      },

      update (currency) {
        if (this.multiple) {

          if (typeof this.selectedCurrencies === "string") {
            this.selectedCurrencies = [this.selectedCurrencies];
          }

          if (!this.isSelected(currency)) {
            this.selectedCurrencies = [ ...this.selectedCurrencies, currency ];
            this.$emit("input", this.selectedCurrencies);

          } else if (this.selectedCurrencies.length > 1) {
            this.selectedCurrencies = this.selectedCurrencies.filter(item => item !== currency);
            this.$emit("input", this.selectedCurrencies);
          }

        } else if (!this.isSelected(currency)) {
          this.selectedCurrencies = [currency];
          this.$emit("input", currency);
        }
      },

      changeToPreferredCurrency () {
        this.selectedCurrencies = [this.preferredCurrency];
        this.$emit("input", this.multiple ? [this.preferredCurrency] : this.preferredCurrency);
      }
    },
    watch: {
      value (value) {
        if (typeof value === "string" && value !== this.selectedCurrencies[0]) {
          this.$set(this.selectedCurrencies, 0, value);
        }
        if (this.multiple && value === null) {
          this.selectedCurrencies = this.currencyList;
        }
      },

      currencyList (value) {
        if (!this.selectedCurrencies || this.multiple && _intersection(this.selectedCurrencies, value).length === 0) {
          this.changeToPreferredCurrency();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .b-skeleton {
    width: auto;
  }
</style>
