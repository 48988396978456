<script>
  export default {
    name: "OfferAsideStyleComponent",

    render () {
      const { promoClass, webmasterClass, trafficTypesClass } = this;

      return this.$scopedSlots.default({
        promoClass,
        webmasterClass,
        trafficTypesClass
      });
    },

    props: {
      hasPromo: {
        type: Boolean,
        required: true
      },

      trafficAmount: {
        type: Number,
        required: true
      }
    },

    computed: {
      promoClass () {
        return {
          "is-4-widescreen": this.isSmallTrafficTypes,
          "is-6-widescreen": this.trafficAmount === 0
        };
      },

      webmasterClass () {
        return {
          "is-4-widescreen": this.isSmallTrafficTypes && this.hasPromo,
          "is-6-widescreen": !this.isSmallTrafficTypes && this.hasPromo || this.isSmallTrafficTypes && !this.hasPromo,
          "is-12": !this.isSmallTrafficTypes && !this.hasPromo,
          "is-6-tablet": this.isSmallTrafficTypes && this.hasPromo
        };
      },

      trafficTypesClass () {
        return {
          "is-4-widescreen": this.isSmallTrafficTypes && this.hasPromo,
          "is-6-widescreen": this.isSmallTrafficTypes && !this.hasPromo,
          "is-12": !this.isSmallTrafficTypes,
          "is-12-tablet": this.isSmallTrafficTypes && this.hasPromo
        };
      },

      isSmallTrafficTypes () {
        const limit = this.hasPromo ? 1 : 2;
        return !!this.trafficAmount && this.trafficAmount <= limit;
      }
    }
  };
</script>