import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";

export default function offer (
  offerId: string,
  isShowCommissions: boolean
): ASTGeneratedOutput {
  const money = ["value","currency"];

  const exclude = [];
  if (!isShowCommissions) {
    exclude.push(
      "advertiserMinimalCommission/value",
      "advertiserMinimalCommission/currency",
      "minimalCommission/value",
      "minimalCommission/currency",
      "finance/webmasterReward/value",
      "finance/webmasterReward/currency",
      "finance/minimalCommission/value",
      "finance/minimalCommission/currency"
    );
  }

  const params = {
    exclude
  };

  const gqlObject = {
    operation: "offer",

    fields: [
      "id",
      "intId",
      "slug",
      "labels",
      "vertical",
      "type",
      "photoUrl",
      "landingUrl",
      "createdAt",
      {
        minimalCommission: money
      },
      {
        advertiserMinimalCommission: money
      },
      {
        advertiserMinimalWriteOff: money
      },
      {
        detail: [
          "name",
          "description",
          "rules",
          "isInHouse"
        ]
      },
      {
        logo: [
          "id",
          "url"
        ]
      },
      {
        previewLogo: [
          "id",
          "url"
        ]
      },
      {
        landing: [
          "currency",
          "cost",
          "preCost"
        ]
      },
      {
        countries: [
          "name",
          "alpha2",
          "alpha3",
          "id"
        ]
      },
      {
        categories: [
          "name",
          "id"
        ]
      },
      {
        trafficTypes: [
          "name",
          "id"
        ]
      },
      {
        finance: [
          {
            webmasterReward: money
          },
          "withdrawType",
          "subGoalType",
          {
            holdTime: [
              "holdMinutes"
            ]
          },
          "currency",
          {
            minimalCommission: money
          }

        ]
      }
    ],

    variables: {
      offerId: {
        type: "Uid!",
        value: offerId
      }
    }

  };

  return Generator.query(gqlObject, params);
}

