<template>
    <OfferWrapper :back-link="backLink"></OfferWrapper>
</template>

<script>
  import OfferWrapper from "@/components/Admin/Offer/OfferWrapper";

  export default {
    name: "OfferView",
    components: { OfferWrapper },
    props: {
      backLink: {
        type: String,
        default: null
      }
    }
  };
</script>

<style scoped>

</style>
