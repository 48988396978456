<template>
    <Card>
        <h2 class="is-size-5 mb-4 mt-3 has-text-weight-bold">
            {{ $t(`common.audienceAnalytic.chart.${ title }`) }}
        </h2>

        <h4
            v-if="hasPie"
            style="font-size: .9rem">
            {{ labelPeriod }}
        </h4>

        <PieChartTemplate
            :items="items"
            :is-loading="isLoading"
            :legend-formatter="legendFormatter"
            :tooltip-formatter="tooltipFormatter"
            :type="type"
            emphasis-label-formatter="{c0}%"
            :mobile-width="400">
        </PieChartTemplate>
    </Card>
</template>

<script>
  import PieChartTemplate from "@/components/Common/Chart/PieChartTemplate";
  import Card from "@/components/Common/Card";
  import { formatEmptyString, formatPercent, toFixed } from "@/filters";
  import { moment } from "@core/filters";
  import { mapState } from "vuex";

  export default {
    name: "AnalyticTargetAudienceDiagram",
    components: {
      PieChartTemplate,
      Card
    },

    props: {
      title: {
        type: String,
        default: null
      },
      data: {
        type: Array,
        default: () => []
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: "pie"
      }
    },

    computed: {
      ...mapState("analyticTargetAudience", {
        firstPeriod: ({ filters }) => filters.firstPeriod
      }),

      labelPeriod () {
        const { dateStart, dateEnd } = this.firstPeriod;
        return `${ this.formatDate(dateStart) } - ${ this.formatDate(dateEnd) }`;
      },

      items () {
        return this.hasPie
          ? this.formatData(this.data).sort((a, b) => b.value - a.value)
          : this.data?.map(({ data, legendName }) => this.formatData(data, legendName));
      },

      hasPie () {
        return this.type === "pie";
      }
    },

    methods: {
      formatEmptyString,
      formatPercent,
      toFixed,

      tooltipFormatter ({ name }) {
        const [key, value] = name?.split(" - ");
        return `${ key }   ${ value }`;
      },

      legendFormatter (name) {
        const [key, value] = name?.split(" - ");
        return `{value|${ key }}      ${ value }`;
      },

      formatData (data, legendName) {
        return data?.map(({ key, value }) => {
          const name = `${ key } - ${ value }`;
          const _value = typeof value === "string" ? Number(value.replace("%", "")) : value;

          return { name, value: _value, legendName };
        }) ?? [];
      },

      formatDate (date) {
        return moment(date, "DD.MM.YYYY");
      }
    }
  };
</script>

<!--suppress SassScssResolvedByNameOnly -->
<style lang="scss" scoped>
  ::v-deep {
    .chart {
      min-height: 270px;
    }

    .hero.is-medium .hero-body {
      padding: 4rem;
    }
  }
</style>
