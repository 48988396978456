<template>
    <TablePlaceholder :data="data">
        <template slot-scope="{ count, items }">
            <LTable
                :page.sync="page"
                :data="items"
                :loading="isLoading"
                :per-page.sync="perPage"
                :count="count"
                limit="small"
                backend-pagination>
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.offer.table.offerWebmasters.labels.webmaster`)"
                    class="statistic-table--column">
                    <div class="is-flex is-align-items-center">
                        <WebmasterOutput :webmaster="row.webmaster"></WebmasterOutput>
                        <LTooltip
                            v-if="isShowCommissions"
                            :label="$t(`admin.users.webmasters.table.values.detailsModalLabel`)"
                            class="ml-auto"
                            position="is-bottom"
                            type="is-info">
                            <b-tag
                                class="is-clickable"
                                type="is-info is-light"
                                disabled
                                @click.native="webmastersTableDetailsModalOpen(row.webmaster)">
                                <b-icon
                                    icon="signal"
                                    size="is-small"></b-icon>
                            </b-tag>
                        </LTooltip>
                    </div>
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.offer.table.offerWebmasters.labels.manager`)"
                    class="statistic-table--column">
                    <CompactList :items="manager(row)">
                        <template #listItem="{ item }">
                            <AdminOutput :admin="item"></AdminOutput>
                        </template>
                        <template #tooltipItem="{ item }">
                            <UserOutput :user="item"></UserOutput>
                        </template>
                        <template #singleItem="{ item }">
                            <AdminOutput :admin="item"></AdminOutput>
                        </template>
                    </CompactList>
                </b-table-column>

                <b-table-column
                    v-if="offer.vertical === VerticalsEnum.NUTRA"
                    v-slot="{ row }"
                    :label="$t(`common.entity.withdrawType.withdrawType`)"
                    class="statistic-table--column">
                    {{ withdrawType(row) }}
                </b-table-column>

                <b-table-column
                    :visible="isShowCommissions"
                    v-slot="{ row }"
                    :label="$t(`admin.offer.table.offerWebmasters.labels.moneyRewards`)"
                    class="statistic-table--column">
                    {{ moneyReward(row) }}
                </b-table-column>

                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.offer.table.offerWebmasters.labels.advertiserWriteOff`)"
                    class="statistic-table--column">
                    {{ advertiserWriteOffRangeOutput(row) }}
                </b-table-column>

                <b-table-column
                    :visible="isShowCommissions"
                    v-slot="{ row }"
                    :label="$t(`admin.offer.table.offerWebmasters.labels.commission`)"
                    class="statistic-table--column">
                    {{ calcCommission(row) }}
                </b-table-column>
            </LTable>
            <WebmastersTableDetailsModal
                :is-active.sync="detailsModal"
                :webmaster="detailsModalData">
            </WebmastersTableDetailsModal>
        </template>
        <template #empty>
            <div class="hero has-text-centered">
                <div class="hero-body">
                    <h2 class="is-size-5 is-bold has-text-weight-medium has-text-grey">
                        <slot name="empty">
                            {{ $t("admin.offer.table.offerWebmasters.labels.empty") }}
                        </slot>
                    </h2>
                </div>
            </div>
        </template>
    </TablePlaceholder>
</template>

<script>
  import WebmastersTableDetailsModal from "@/components/Admin/Users/WebmastersTab/WebmastersTableDetailsModal";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import WebmasterOutput from "@/components/Common/Output/WebmasterOutput.vue";
  import AdminOutput from "@/components/Common/Output/AdminOutput.vue";
  import UserOutput from "@/components/Common/Output/UserOutput.vue";
  import { formatCurrency, formatEmptyString } from "@core/filters";
  import { getMinMax } from "@core/helpers/getMinMax";
  import { GET_OFFER_WEBMASTERS, UPDATE_WEBMASTER_ID } from "@core/store/action-constants";
  import { SET_EMPTY, UPDATE_PAGINATION } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";
  import LTable from "@/components/Common/LTable";
  import { DebounceUpdateByWatchedParams } from "@core/mixins";
  import CompactList from "@/components/Common/Lists/CompactList.vue";
  import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
  import { useOffers } from "@/stores/admin/offers/offersStore";

  export default {
    name: "OfferWebmastersTable",

    mixins: [DebounceUpdateByWatchedParams],

    components: {
      TablePlaceholder,
      WebmasterOutput,
      AdminOutput,
      UserOutput,
      WebmastersTableDetailsModal,
      LTable,
      CompactList
    },
    props: {
      offer: {
        type: Object,
        default: null
      }
    },

    setup () {
      const { isShowCommissions } = useOffers();
      return { isShowCommissions };
    },

    data () {
      return {
        detailsModal: false,
        detailsModalData: null
      };
    },

    computed: {
      VerticalsEnum () {
        return VerticalsEnum;
      },

      ...mapState("admin/offer/webmasters", {
        data: "data",
        offerId: "offerId",
        updateParams: ({ pagination }) => ({ ...pagination })
      }),

      ...mapFields("admin/offer/webmasters", {
        fields: [
          "page",
          "perPage"
        ],
        base: "pagination",
        action: UPDATE_PAGINATION
      }),

      isPaginated () {
        return this.data && !isNaN(this.data.count) ? this.data.count > this.perPage : false;
      },

      isLoading () {
        return this.$wait(`admin/offer/webmasters/${ GET_OFFER_WEBMASTERS }`);
      },

      getOfferId () {
        return this.offer?.id;
      }
    },
    methods: {
      ...mapActions("admin", {
        GET_OFFER_WEBMASTERS:`offer/webmasters/${ GET_OFFER_WEBMASTERS }` ,
        UPDATE_PAGINATION: `offer/webmasters/${ UPDATE_PAGINATION }`,
        UPDATE_WEBMASTER_ID: `users/webmasters/detail/${ UPDATE_WEBMASTER_ID }`
      }),

      ...mapMutations("admin/offer/webmasters", [
        SET_EMPTY
      ]),

      manager (row) {
        return row.webmaster?.admins;
      },

      getAdvertiserWriteOffRange (writeOffs = []) {
        if (writeOffs?.length) {
          const { min, max } = getMinMax(writeOffs);
          return [min, max];
        }
      },

      advertiserWriteOffRangeOutput ({ advertiserWriteOffs }) {
        const advertiserWriteOffRange = this.getAdvertiserWriteOffRange(advertiserWriteOffs);

        if (advertiserWriteOffRange) {
          const [minWriteOff, maxWriteOff] = advertiserWriteOffRange
            .map(writeOff => this.formatCurrency(writeOff.value, writeOff.currency));

          return minWriteOff === maxWriteOff ? minWriteOff : `${ minWriteOff } - ${ maxWriteOff }`;
        }

        return this.formatEmptyString();
      },

      calcCommission ({ advertiserWriteOffs, individualRate }) {
        const { offer } = this;
        const advertiserWriteOffRange = this.getAdvertiserWriteOffRange(advertiserWriteOffs);

        if (advertiserWriteOffRange) {
          const reward = individualRate?.moneyReward?.value ?? offer?.finance?.webmasterReward?.value;
          const [minWriteOff, maxWriteOff] = advertiserWriteOffRange
            .map(writeOff => this.formatCurrency(writeOff.value - reward, writeOff.currency));

          return minWriteOff === maxWriteOff ? minWriteOff : `${ minWriteOff } - ${ maxWriteOff }`;
        }

        return this.formatEmptyString();
      },

      withdrawType (row) {
        const withdrawType = (row.individualRate?.withdrawType || this.offer?.finance?.withdrawType)?.toLowerCase();
        return withdrawType ? this.$t(`common.entity.withdrawType.${ withdrawType }`) : this.formatEmptyString();
      },

      moneyReward (row) {
        const reward = row.individualRate?.moneyReward || this.offer?.finance?.webmasterReward;
        return this.formatCurrency(reward?.value, reward?.currency);
      },

      webmastersTableDetailsModalOpen (value) {
        this.detailsModal = true;
        this.detailsModalData = value;
        this.UPDATE_WEBMASTER_ID(value.id);
      },

      updated () {
        this.GET_OFFER_WEBMASTERS(this.getOfferId);
      },

      formatEmptyString,
      formatCurrency
    },

    watch: {
      offer: {
        immediate: true,
        handler (value) {
          if (value) {
            this.updated();
          }
        }
      }
    },

    destroyed () {
      this.SET_EMPTY();
    }
  };
</script>

<style lang="scss" scoped></style>
