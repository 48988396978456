





















import { OfferLabelsToColorsEnum } from "@core/store/types/admin/offers/enums/offerLabelsToColorsEnum";
import { OfferLabelsEnum } from "@core/store/types/admin/offers/enums/offerLabelsEnum";
import { withDefaults, defineProps } from "@vue/runtime-dom";
interface Props {
  labels: (OfferLabelsEnum)[] | never[];
  size: "small" | "medium" | "large";
}
const __sfc_main = {};
__sfc_main.props = {
  labels: {
    key: "labels",
    required: false,
    type: Array,
    default: () => []
  },
  size: {
    key: "size",
    required: false,
    type: String,
    default: "small"
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {
    OfferLabelsToColorsEnum,
    OfferLabelsEnum
  };
};
export default __sfc_main;
