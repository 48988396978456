<template>
    <div class="wrapper">
        <div
            class="backLink">
            <router-link
                :to="routerLink"
                class="table-item--link">
                <b-icon
                    icon="angle-left"
                    size="is-small"></b-icon>
                {{ $t(`common.entity.offer.goBack`) }}
            </router-link>
        </div>
        <div class="columns is-multiline offer">
            <OfferCommon
                class="column is-12 common"
                :has-call-center="hasCallCenter"
                :offer="offer">
            </OfferCommon>
            <div class="column aside">
                <aside class="columns is-multiline is-sticky">
                    <slot name="aside"></slot>

                    <OfferTrafficTypes
                        v-if="hasTrafficTypes"
                        class="column is-12-fullhd"
                        :traffic-types="trafficTypes">
                    </OfferTrafficTypes>
                </aside>
            </div>

            <div class="column is-12 others p-0">
                <slot name="column"></slot>
            </div>
        </div>
    </div>
</template>

<script>
  import OfferCommon from "@/components/Common/Offer/OfferCommon";
  import OfferTrafficTypes from "@/components/Common/Offer/OfferTrafficTypes";
  import { mapGetters } from "vuex";

  export default {
    name: "Offer",
    components: {
      OfferCommon,
      OfferTrafficTypes
    },

    props: {
      offer: {
        type: Object,
        default: null
      },
      trafficTypes: {
        type: Array,
        default: null
      },
      backLink: {
        type: String,
        default: null
      },
      hasCallCenter: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      ...mapGetters(["role", "isPartnerNetwork"]),

      hasPromo () {
        return Boolean(this.offer?.landingUrl || this.offer?.photoUrl) && !(this.role === "webmaster" && !this.offerIsJoined);
      },

      trafficAmount () {
        return this.trafficTypes?.length ?? 0;
      },

      routerLink () {
        return this.backLink || { name: `${ this.role }:offers` };
      },

      hasTrafficTypes () {
        return this.trafficTypes?.length;
      },

      offerIsJoined () {
        return this.offer.webmasterJoinedState === "JOINED";
      }
    }
  };
</script>

<style lang="scss" scoped>
    .offer {
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas:
                "common"
                "aside"
                "others";
    }

    .common {
        grid-area: common;
    }

    .aside {
        grid-area: aside;
    }

    .others {
        grid-area: others;
    }

    //noinspection SassScssUnresolvedMixin
    @include fullhd {
        .offer {
            grid-template-columns: 75% 25%;
            grid-template-areas:
                "common aside"
                "others aside";
        }
    }

    .columns {
        .column {
            .content {
                > .level {
                    align-items: flex-start;
                }
            }
        }
    }

    .mr-5 {
        margin-right: 5px;
    }

    ::v-deep .asideTable-wrapper {
        .asideTable {
            margin: -0.75em;
        }

        th, td {
            padding: 0.75em;
        }

        tbody {
            font-size: 1.2em;
        }
    }

    .asideTable {
        ::v-deep td {
            border-top: 1px solid #d5d5d5 !important;
        }
    }

    ul {
        li {
            list-style: none;
            font-weight: 700 !important;
        }
    }

    ::v-deep {
        .card {
          height: 100%;
        }

        .tag {
            cursor: pointer;
        }

        .content {
            padding: 0.8em 0;
        }
    }

    .backLink {
        margin: 1em;
    }

    .card {
        &-header-title {
            font-size: $size-base;
        }
    }

    .b-tooltip {
        display: flex;
    }

    .is-sticky {
        position: sticky;
        top: $navbar-height;
        z-index: 1;
    }
</style>
