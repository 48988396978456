<template>
    <figure
        :class="[size ? size : 'is-1by1']"
        class="image">
        <picture>
            <source
                media="(max-width: 50px)"
                :srcset="generateImage('Small')">
            <img
                :src="generateImage('Big')"
                :alt="alt">
        </picture>
    </figure>
</template>

<script>
  export default {
    name: "OfferLogo",
    props: {
      image: {
        required: true,
        validator (value) {
          return typeof value === "object" || value == null;
        }
      },
      previewLogo: {
        type: Object,
        default: null
      },

      alt: {
        type: String,
        required: true
      },

      vertical: {
        type: String,
        default: "NUTRA"
      },

      size: {
        type: String,
        default: null,
        validator (value) {
          return ["is-32x32", "is-48x48", "is-256x256", null].includes(value);
        }
      }
    },

    methods: {
      getImageSize (value) {
        if(typeof value === "string") {
          return +value.split("x")[1];
        }
      },

      imageUrl (value) {
        const url = new URL(value);
        url.searchParams.set("s", this.getImageSize(this.size));
        return url;
      },

      generateImage (name) {
        const { image, previewLogo } = this;
        const regex = /(\.mp4)/gi;

        if (image && image.url && !regex.test(image.url)) {
          return this.imageUrl(image.url);
        } else if(previewLogo && previewLogo.url) {
          return this.imageUrl(previewLogo.url);
        }
        return require(`@/assets/Offer/${ this.vertical }/${ name }.png`);
      }
    }
  };
</script>

<style lang="scss" scoped></style>
