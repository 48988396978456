<template>
    <div>
        <b-tabs v-model="activeTab">
            <b-tab-item
                :key="i"
                v-for="(tab, i) in tabs"
                :label="$t(`admin.offer.table.callCenters.labels.${tab}`)">
                <!--                <TablePlaceholder :data="getCallCentersUTC(callCenters)">-->
                <!--                    <template slot-scope="{ count, items }">-->
                <!--                        <b-table-->
                <!--                            :data="items"-->
                <!--                            :total="count"-->
                <!--                            hoverable-->
                <!--                            striped>-->
                <!--                            <template>-->
                <!--                                <b-table-column-->
                <!--                                    v-slot="{ row }"-->
                <!--                                    :label="$t(`common.entity.callCenters.labels.name`)"-->
                <!--                                    class="statistic-table&#45;&#45;column">-->
                <!--                                    <strong class="has-text-weight-semibold">-->
                <!--                                        {{ row.name }}-->
                <!--                                    </strong>-->
                <!--                                </b-table-column>-->
                <!--                                <b-table-column-->
                <!--                                    :key="index"-->
                <!--                                    v-for="(day, index) in daysOfWeek"-->
                <!--                                    v-slot="{ row }"-->
                <!--                                    :label="$t(`common.entity.callCenters.daysOfWeek.full.${day}`)"-->
                <!--                                    class="statistic-table&#45;&#45;column">-->
                <!--                                    <LTooltip-->
                <!--                                        :active="!!dayEvents(row.timeTables[day]).tooltipText"-->
                <!--                                        :label="dayEvents(row.timeTables[day]).tooltipText"-->
                <!--                                        position="is-top"-->
                <!--                                        type="is-dark">-->
                <!--                                        <span-->
                <!--                                            :class="dayEvents(row.timeTables[day]).class"-->
                <!--                                            style="white-space: pre">-->
                <!--                                            {{ dayEvents(row.timeTables[day]).label || dayEvents(row.timeTables[day]) }}-->
                <!--                                        </span>-->
                <!--                                    </LTooltip>-->
                <!--                                </b-table-column>-->
                <!--                            </template>-->
                <!--                        </b-table>-->
                <!--                    </template>-->
                <!--                </TablePlaceholder>-->
                <TilesTable
                    :columns="callCentersUTC.length > 1 ? 2 : 1"
                    :items="callCentersUTC">
                    <template slot-scope="{ item }">
                        <CallCenter
                            view-only
                            :call-center="item"
                            class="callCenter">
                        </CallCenter>
                    </template>
                </TilesTable>
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
  import { CallCenters } from "@core/mixins";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import TilesTable from "@/components/Common/TilesTable.vue";
  import CallCenter from "@/components/Admin/Users/AdvertisersTab/CallСenters/CallCenter.vue";
  
  export default {
    name: "CallCentersTable",
    mixins: [CallCenters],
    components: {
      CallCenter,
      TilesTable
      // TablePlaceholder
    },

    props: {
      callCenters: {
        type: Object,
        default: null
      }
    },
    data () {
      return {
        activeTab: 0
      };
    },
    computed: {
      callCentersUTC () {
        return this.getCallCentersUTC(this.callCenters).items || [];
      },
      
      daysOfWeek () {
        return ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
      },

      userTimeZone () {
        return this.$store.getters.timezone;
      },

      tabs () {
        return ["userTimeZone", "callCentersTimeZone"];
      }

    },

    watch: {
      activeTab (value) {
        this.$emit("changeTab", value);
      }
    }
  };
</script>

<style lang="scss" scoped>
    .time {
        min-width: 72px;
    }

    ::v-deep{
        .table-wrapper {
            overflow-x: auto;
        }
    }
</style>
