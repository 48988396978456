import { CREATE_BASE_LINK } from "@core/store/action-constants";
import { mapGetters } from "vuex";

// @vue/components
export const FlowsTable = {
  computed: {
    ...mapGetters("editLinkModal", {
      createBaseLink: CREATE_BASE_LINK
    })
  },
  
  methods: {
    createLink (params) {
      try {
        return this.createBaseLink(params);
      } catch (e) {
        return this.$t("webmaster.flows.table.values.noLink");
      }
    },
    
    editFlow (flowId) {
      this.$router.push({
        name: "webmaster:flows:edit",
        query: {
          flowId
        }
      }).catch(_ => {});
    }
  }
};
