<template>
    <ModalBox
        :is-active.sync="_isActive"
        :is-loading="isLoading"
        @after-enter="afterEnter">
        <template #header>
            <h5 class="title is-5">
                {{ $t("admin.offer.table.callCenters.labels.name") }}
            </h5>
        </template>

        <div class="is-relative">
            <CallCentersTable
                :call-centers="callCenters"
                @changeTab="$emit('changeTab', $event)">
            </CallCentersTable>
        </div>
    </ModalBox>
</template>

<script>
  import CallCentersTable from "@/components/Common/Offer/CallCentersTable";
  import ModalBox from "@/components/Common/Modals/ModalBox";
  import { UPDATE_MODAL_ACTIVE } from "@core/store/action-constants";
  import { mapActions, mapState } from "vuex";

  export default {
    name: "OfferCallCentersModal",
    components: { CallCentersTable, ModalBox },

    props: {
      isActive: {
        type: Boolean,
        default: false
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      callCenters: {
        type: Object,
        default: null
      }
    },

    computed: {
      ...mapState("callCentersOfferModal", {
        isModalActive: ({ isModalActive }) => isModalActive
      }),

      _isActive: {
        get () {
          return this.isModalActive;
        },
        set (value) {
          this.UPDATE_MODAL_ACTIVE(value);
        }
      }
    },

    methods: {
      ...mapActions("callCentersOfferModal", [
        UPDATE_MODAL_ACTIVE
      ]),

      afterEnter () {
        this.$emit("changeTab");
      }
    }
  };
</script>

<style lang="scss" scoped>
  .callCenters {
    width: 400px;
    height: 150px;
  }

  ::v-deep {
    .animation-content {
      max-width: 100% !important;
    }

    .modal-card {
      width: auto;
    }

      .content {
        padding: 1em;
      }

  }
</style>