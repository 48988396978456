var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TablePlaceholder',{attrs:{"data":_vm.data},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var count = ref.count;
var items = ref.items;
return [_c('LTable',{attrs:{"page":_vm.page,"data":items,"loading":_vm.isLoading,"per-page":_vm.perPage,"count":count,"limit":"small","backend-pagination":""},on:{"update:page":function($event){_vm.page=$event},"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event}}},[_c('b-table-column',{staticClass:"statistic-table--column",attrs:{"label":_vm.$t("admin.offer.table.offerWebmasters.labels.webmaster")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"is-flex is-align-items-center"},[_c('WebmasterOutput',{attrs:{"webmaster":row.webmaster}}),(_vm.isShowCommissions)?_c('LTooltip',{staticClass:"ml-auto",attrs:{"label":_vm.$t("admin.users.webmasters.table.values.detailsModalLabel"),"position":"is-bottom","type":"is-info"}},[_c('b-tag',{staticClass:"is-clickable",attrs:{"type":"is-info is-light","disabled":""},nativeOn:{"click":function($event){return _vm.webmastersTableDetailsModalOpen(row.webmaster)}}},[_c('b-icon',{attrs:{"icon":"signal","size":"is-small"}})],1)],1):_vm._e()],1)]}}],null,true)}),_c('b-table-column',{staticClass:"statistic-table--column",attrs:{"label":_vm.$t("admin.offer.table.offerWebmasters.labels.manager")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('CompactList',{attrs:{"items":_vm.manager(row)},scopedSlots:_vm._u([{key:"listItem",fn:function(ref){
var item = ref.item;
return [_c('AdminOutput',{attrs:{"admin":item}})]}},{key:"tooltipItem",fn:function(ref){
var item = ref.item;
return [_c('UserOutput',{attrs:{"user":item}})]}},{key:"singleItem",fn:function(ref){
var item = ref.item;
return [_c('AdminOutput',{attrs:{"admin":item}})]}}],null,true)})]}}],null,true)}),(_vm.offer.vertical === _vm.VerticalsEnum.NUTRA)?_c('b-table-column',{staticClass:"statistic-table--column",attrs:{"label":_vm.$t("common.entity.withdrawType.withdrawType")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.withdrawType(row))+" ")]}}],null,true)}):_vm._e(),_c('b-table-column',{staticClass:"statistic-table--column",attrs:{"visible":_vm.isShowCommissions,"label":_vm.$t("admin.offer.table.offerWebmasters.labels.moneyRewards")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.moneyReward(row))+" ")]}}],null,true)}),_c('b-table-column',{staticClass:"statistic-table--column",attrs:{"label":_vm.$t("admin.offer.table.offerWebmasters.labels.advertiserWriteOff")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.advertiserWriteOffRangeOutput(row))+" ")]}}],null,true)}),_c('b-table-column',{staticClass:"statistic-table--column",attrs:{"visible":_vm.isShowCommissions,"label":_vm.$t("admin.offer.table.offerWebmasters.labels.commission")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.calcCommission(row))+" ")]}}],null,true)})],1),_c('WebmastersTableDetailsModal',{attrs:{"is-active":_vm.detailsModal,"webmaster":_vm.detailsModalData},on:{"update:isActive":function($event){_vm.detailsModal=$event},"update:is-active":function($event){_vm.detailsModal=$event}}})]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"hero has-text-centered"},[_c('div',{staticClass:"hero-body"},[_c('h2',{staticClass:"is-size-5 is-bold has-text-weight-medium has-text-grey"},[_vm._t("empty",function(){return [_vm._v(" "+_vm._s(_vm.$t("admin.offer.table.offerWebmasters.labels.empty"))+" ")]})],2)])])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }