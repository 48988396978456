<template>
    <BlockBox
        :title="title">
        <TablePlaceholder :data="data">
            <template #default="{ items }">
                <b-notification
                    v-if="isNotificationShown"
                    :closable="false"
                    type="is-info">
                    {{ $t("common.entity.transits.notifications.selected") }} {{ checked.length }} / {{ data.count }}
                </b-notification>
                <b-table
                    :checkable="checkable"
                    :checked-rows.sync="checkedArray"
                    :data="items"
                    :sticky-header="stickyHeaders"
                    class="common-table"
                    hoverable
                    striped>
                    <b-table-column
                        v-slot="{ row }"
                        :label="labelName">
                        <ColoredStatus
                            :show-status="showReady && row.hasOwnProperty('isReady')"
                            :type="coloredStatus(row.isReady)"
                            tooltip-position="is-bottom"
                            tooltip-type="is-dark">
                            <span class="mr-2">
                                {{ row.name }}
                            </span>
                            <template v-if="row.isNew">
                                <b-tag
                                    class="py-1"
                                    type="is-info">
                                    New
                                </b-tag>
                            </template>
                            <template>
                                <LTooltip
                                    v-if="row.isTikTok"
                                    :label="$t('common.entity.landings.modal.tooltip.tikTok')"
                                    type="is-dark"
                                    size="is-small">
                                    <b-tag
                                        class="ml-1 py-1"
                                        type="is-dark">
                                        {{ $t("common.entity.landings.modal.add.body.tikTok") }}
                                    </b-tag>
                                </LTooltip>
                            </template>
                        </ColoredStatus>
                    </b-table-column>

                    <b-table-column
                        v-slot="{ row }"
                        v-if="offerConversion"
                        sortable
                        field="cr"
                        :label="offerConversion">
                        <InlineLoader
                            v-if="isLoadingConversion"
                            active>
                        </InlineLoader>
                        <template v-else>
                            {{ row.cr ? `${ row.cr }%` : "-" }}
                        </template>
                    </b-table-column>

                    <b-table-column
                        v-slot="{ row }"
                        v-if="date"
                        :label="date">
                        {{ formatDate(row.createdAt) }}
                    </b-table-column>

                    <b-table-column
                        v-slot="{ row }"
                        :label="labelLanguage">
                        {{ formatLanguage(row) }}
                    </b-table-column>

                    <b-table-column
                        v-slot="{ row }"
                        v-if="labelVisibility"
                        :label="labelVisibility">
                        <span v-if="row.isPublic">
                            {{ $t("common.entity.landings.table.descriptions.all") }}
                        </span>

                        <span v-else-if="!row.webmasters.length && !row.isPublic">
                            {{ $t("common.entity.landings.table.descriptions.none") }}
                        </span>

                        <CompactList
                            v-else
                            :items="row.webmasters">
                            <template #listItem="{ item }">
                                <UserOutput :user="item"></UserOutput>
                            </template>
                            <template #tooltipItem="{ item }">
                                <UserOutput :user="item"></UserOutput>
                            </template>
                            <template #singleItem="{ item }">
                                <UserOutput :user="item"></UserOutput>
                            </template>
                        </CompactList>
                    </b-table-column>

                    <b-table-column
                        v-slot="{ row }"
                        v-if="labelLastFile"
                        :label="labelLastFile">
                        <LTooltip
                            :active="isFailedStatus(row) && reasonForFail(row).length !== 0"
                            :label="reasonForFail(row)"
                            :max-width="250"
                            position="is-bottom"
                            type="is-danger">
                            <ColoredStatus
                                :type="coloredStatusLastFile(row.lastFile)"
                                tooltip-position="is-bottom"
                                tooltip-type="is-dark">
                                {{ hasFile(row.lastFile) }}
                            </ColoredStatus>
                        </LTooltip>
                    </b-table-column>

                    <b-table-column
                        v-slot="{ row }"
                        v-if="labelCurrentFile"
                        :label="labelCurrentFile">
                        <ColoredStatus
                            :type="coloredStatus(row.currentFile)"
                            tooltip-position="is-bottom"
                            tooltip-type="is-dark">
                            {{ hasFile(row.currentFile) }}
                        </ColoredStatus>
                    </b-table-column>

                    <b-table-column
                        v-if="unloading"
                        :label="$t('common.entity.landings.table.labels.unloading')"
                        centered>
                        <template #default="{ row }">
                            <b-button
                                :loading="isLoadingFile"
                                icon-left="file-download"
                                type="is-info is-light"
                                :disabled="!row.isExistCurrentFile"
                                @click="$emit('file-download', row)">
                            </b-button>
                        </template>
                    </b-table-column>

                    <b-table-column
                        v-slot="{ row }"
                        width="1%">
                        <div class="columns is-flex is-variable is-1">
                            <div class="column">
                                <b-button
                                    :disabled="!isAbleToPreview(row)"
                                    :href="row.previewLink"
                                    class="bulma-button"
                                    icon-left="eye"
                                    icon-pack="far"
                                    size="is-small"
                                    tag="a"
                                    target="_blank"
                                    type="is-info">
                                    {{ $t("common.entity.transits.table.buttons.preview") }}
                                </b-button>
                            </div>
                            <slot
                                :row="row"
                                name="buttons">
                            </slot>
                        </div>
                    </b-table-column>
                    <template #footer>
                        <InfiniteLoading
                            v-if="data.items && data.items.length && isInfiniteLoading_"
                            @infinite="infiniteHandler">
                            <div slot="spinner"></div>
                        </InfiniteLoading>
                    </template>
                </b-table>
            </template>
            <template #empty>
                <div class="hero has-text-centered">
                    <div class="hero-body">
                        <h2 class="is-size-5 is-bold has-text-weight-medium has-text-grey">
                            <slot name="empty">
                                {{ $t("common.entity.landings.table.empty") }}
                            </slot>
                        </h2>
                    </div>
                </div>
            </template>
        </TablePlaceholder>

        <template #title-content-right>
            <slot name="title-content-right"></slot>
        </template>

        <slot name="footer"></slot>

        <LLoading
            v-if="isLoading"
            :is-full-page="false"
            active></LLoading>
    </BlockBox>
</template>

<script>
  import BlockBox from "@/components/Common/BlockBox.vue";
  import InlineLoader from "@/components/Common/Controls/InlineLoader.vue";
  import ColoredStatus from "@/components/Common/ColoredStatus.vue";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder.vue";
  import { formatEmptyString } from "@core/filters";
  import InfiniteLoading from "vue-infinite-loading";
  import LLoading from "@/components/Common/LLoading.vue";
  import CompactList from "@/components/Common/Lists/CompactList.vue";
  import UserOutput from "@/components/Common/Output/UserOutput.vue";
  import momentjs from "moment";
  import { GET_OFFER_CONVERSION } from "@core/store/action-constants";

  export default {
    name: "OfferLandings",

    components: {
      UserOutput,
      CompactList,
      LLoading,
      BlockBox,
      TablePlaceholder,
      InlineLoader,
      InfiniteLoading,
      ColoredStatus
    },

    props: {
      title: {
        type: String,
        required: true
      },
      data: {
        type: Object,
        default: () => {}
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      isLoadingFile: {
        type: Boolean,
        default: false
      },
      checkable: {
        type: Boolean,
        default: false
      },
      checked: {
        type: Array,
        default: () => []
      },
      checkedFormatter: {
        type: Function,
        default: ({ id }) => id
      },
      labelName: {
        type: String,
        required: true
      },
      labelLanguage: {
        type: String,
        required: true
      },
      labelVisibility: {
        type: String,
        default: null
      },
      labelLastFile: {
        type: String,
        default: null
      },
      labelCurrentFile: {
        type: String,
        default: null
      },
      date: {
        type: String,
        default: null
      },
      unloading: {
        type: Boolean,
        default: false
      },
      offerConversion: {
        type: String,
        default: null
      },
      stickyHeaders: {
        type: Boolean,
        default: true
      },
      pagination: {
        type: Object,
        default: null
      },
      showReady: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      checkedArray: {
        get () {
          return this.checked;
        },
        set (array) {
          this.$emit("update:checked", array.map(this.checkedFormatter));
        }
      },

      isInfiniteLoading_ () {
        return this.pagination.perPage * this.pagination.page < this.data.count;
      },

      isNotificationShown () {
        return this.data.count > 0 && this.checked.length > 0;
      },

      isLoadingConversion () {
        return this.$wait(`admin/offer/offerConversionList/${ GET_OFFER_CONVERSION }`) ||
          this.$wait(`webmaster/offer/offerConversionList/${ GET_OFFER_CONVERSION }`) ||
          this.$wait(`advertiser/offer/offerConversionList/${ GET_OFFER_CONVERSION }`);
      }
    },

    methods: {
      infiniteHandler ($state) {
        this.$emit("infiniteHandler", $state);
      },

      isAbleToPreview (row) {
        return row.previewLink && row.previewLink.length;
      },

      coloredStatus (status) {
        return status ? "is-success" : "is-danger";
      },

      coloredStatusLastFile (lastFile) {
        if (!lastFile) {
          return "is-danger";
        }
        return {
          "is-danger": lastFile.status === "failed",
          "is-warning": lastFile.status === "unprocessed",
          "is-success": lastFile.status === "succeeded"
        };
      },

      formatLanguage (row) {
        return formatEmptyString(row.language ? this.$t(`common.languages.${ row.language.alias }`) : null);
      },

      isFailedStatus (row) {
        return row.lastFile?.status === "failed";
      },

      hasFile (file) {
        return file
          ? this.$t(`common.entity.landings.table.values.status.${ file.status }`)
          : this.$t("common.entity.landings.table.values.noFile");
      },

      reasonForFail (row) {
        return row.lastFile?.reasonForFail.taskComment ?? this.$t("common.errors.ERROR");
      },

      formatDate (date) {
        return momentjs(date).format("DD.MM.YYYY");
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .b-table .table-wrapper.has-sticky-header tr:first-child th {
          background-color: white;
        }

        .table-wrapper {
            overflow-x: unset !important;
        }
    }

    .bulma-button {
        height: 2.5em;
    }
</style>
