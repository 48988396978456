<template>
    <div class="tile is-parent tile-item">
        <article
            class="tile is-flex is-vertical is-child box content"
            @click.prevent="open">
            <header class="tile-item-header">
                <slot name="header">
                    <slot name="title">
                        <h3
                            v-if="item.title != null"
                            class="title is-4">
                            <slot name="title-text">
                                {{ item.title }}
                            </slot>
                        </h3>
                    </slot>
                    <slot name="subtitle">
                        <h4
                            v-if="item.subtitle != null"
                            class="subtitle is-6">
                            <slot name="subtitle-text">
                                {{ item.subtitle }}
                            </slot>
                        </h4>
                    </slot>
                </slot>
            </header>
            <p class="tile-item-content">
                <slot name="content">
                    {{ item.content }}
                </slot>
            </p>
            <footer class="tile-item-footer">
                <slot name="footer">
                    <div class="has-text-right">
                        <slot
                            :edit="edit"
                            :remove="remove"
                            name="buttons">
                            <LTooltip
                                :label="$t(`common.entity.tileItem.tooltip.edit`)"
                                position="is-bottom"
                                type="is-warning">
                                <b-button
                                    icon-left="pen"
                                    size="is-small"
                                    type="is-warning is-light"
                                    @click.prevent.stop="edit"></b-button>
                            </LTooltip>
            
                            <LTooltip
                                :label="$t(`common.entity.tileItem.tooltip.delete`)"
                                position="is-bottom"
                                type="is-danger">
                                <b-button
                                    icon-left="trash-alt"
                                    icon-pack="far"
                                    size="is-small"
                                    type="is-danger is-light"
                                    @click.prevent.stop="remove"></b-button>
                            </LTooltip>
                        </slot>
                    </div>
                </slot>
            </footer>
        </article>
    </div>
</template>

<script>
  export default {
    name: "TileItem",
    components: {},

    props: {
      item: {
        type: Object,
        required: true
      }
    },

    methods: {
      open () {
        this.$emit("open");
      },

      edit () {
        this.$emit("edit");
      },

      remove () {
        this.$emit("delete");
      }
    }
  };
</script>

<style lang="scss" scoped>
    .tile-item {
        height: 100%;
        cursor: pointer;

        .is-child {
            justify-content: space-between;
        }

        &-content {
            min-height: 1.5em;
        }

        &-footer {
            .tippy:not(:last-child) {
                margin-right: 0.8em;
            }
        }
    }
</style>
