<template>
    <ModalBox
        :is-active.sync="isModalActive"
        :is-loading="isLoading"
        @after-leave="close">
        <template #header>
            <h5 class="title is-5">
                {{ title }}
            </h5>
        </template>
        <CustomInput
            :value="outputURL"
            :label="$t(`webmaster.flows.modals.flowsLinkModal.labels.url`)"
            copy
            expanded
            readonly>
        </CustomInput>
        <div class="columns is-multiline">
            <div
                :key="key"
                v-for="(value, key) in fields"
                class="column is-4">
                <b-field :label="translatedLabel(key)">
                    <GlobalSourceFilter
                        v-if="key === 'globalSource'"
                        :placeholder="translatedLabel(key)"
                        :value="value"
                        @input="updateField(key, $event)">
                    </GlobalSourceFilter>
                    <LInput
                        v-else
                        :placeholder="translatedLabel(key)"
                        :value="value"
                        @input="updateField(key, $event)">
                    </LInput>
                </b-field>
            </div>
        </div>
        <template #footer>
            <div class="columns is-flex-grow-1">
                <div class="column">
                    <b-button
                        class="is-fullwidth"
                        type="is-light-button"
                        @click="close">
                        {{ $t("common.buttons.close") }}
                    </b-button>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { Modal } from "@core/mixins";
  import { UPDATE_FIELDS } from "@core/store/action-constants";
  import { SET_EMPTY, UPDATE_MODAL_ACTIVE } from "@core/store/mutation-constants";
  import { mapActions, mapState } from "vuex";
  import CustomInput from "@/components/Common/Select/CustomInput.vue";
  import { FlowsTable } from "@/components/Webmaster/Flows/FlowsMixin";
  import GlobalSourceFilter from "@/components/Common/Select/GlobalSourceFilter.vue";
  import VueI18n from "vue-i18n";

  export default {
    name: "FlowsEditLinkModal",
    mixins: [Modal, FlowsTable],

    components: {
      GlobalSourceFilter,
      CustomInput,
      ModalBox
    },

    props: {
      isLoading: {
        type: Boolean,
        default: false
      },

      title: {
        type: [String, VueI18n.TranslateResult],
        default () {
          return this.$t("webmaster.flows.modals.flowsLinkModal.title");
        }
      }
    },
    
    computed: {
      ...mapState("editLinkModal", {
        modalActive: ({ isModalActive }) => isModalActive,
        fields: ({ fields }) => fields,
        url: ({ url }) => url
      }),

      outputURL () {
        return this.url ?? this.$t("webmaster.flows.table.values.noLink");
      },

      isModalActive: {
        get () {
          return this.modalActive;
        },
  
        set (value) {
          this.UPDATE_MODAL_ACTIVE(value);
        }
      }
    },
    
    methods: {
      ...mapActions("editLinkModal", {
        UPDATE_MODAL_ACTIVE,
        UPDATE_FIELDS,
        SET_EMPTY
      }),

      close () {
        this.SET_EMPTY();
      },
      
      translatedLabel (key) {
        const translationKey = `webmaster.flows.modals.flowsLinkModal.labels.${ key }`;
        
        return this.$te(translationKey) ? this.$t(translationKey) : key;
      },
      
      updateField (key, payload) {
        payload = payload === "" ? null : payload;
        
        this.UPDATE_FIELDS({ [key]: payload });
      }
    }
  };
</script>

<style lang="scss" scoped>
    .modal {
        ::v-deep {
            .animation-content {
                max-width: 1600px !important;
                width: 80vw;
            }

            .modal-card {
                max-width: 100%;
                width: auto;
            }
        }
    }

    .is-expanded {
        width: 100%;
    }
</style>
