<template>
    <Select
        v-model="selectedGlobalSource"
        :get-data-vuex="getGlobalSource"
        :placeholder="placeholder"
        class="white"
        field="value"
        prop="name"
        :multiple="multiple">
    </Select>
</template>

<script>
  import Select from "@/components/Common/Select/Select.vue";
  import { mapActions, mapState } from "vuex";
  import { GET_GLOBAL_SOURCE, SET_EMPTY } from "@core/store/action-constants";
  import { filteredDataBySearch } from "@core/helpers/filteredDataBySearch";
  import { i18n } from "@core/plugins";

  export default {
    name: "GlobalSourceFilter",
    components: { Select },

    props: {
      value: {
        type: [Array, String],
        default: null
      },

      placeholder: {
        type: String,
        default: ""
      },

      multiple: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState("globalSourceFilters", {
        globalSource: state => state.data
      }),

      selectedGlobalSource: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit("input", value);
        }
      }
    },

    methods: {
      ...mapActions("globalSourceFilters", [
        GET_GLOBAL_SOURCE,
        SET_EMPTY
      ]),

      async getGlobalSource (label) {
        if (!this.globalSource.items) {
          await this.GET_GLOBAL_SOURCE();
        }

        const formatData = this.globalSource.items?.map(item => {
          const translatedName = `common.entity.globalSource.${ item.value }`;
          const value = i18n.te(translatedName) ? i18n.t(translatedName) : item.value;

          return {
            ...item,
            value
          };
        });

        if (label) {
          return filteredDataBySearch(formatData, label, ["value"]);
        } else {
          return {
            items: formatData,
            count: formatData?.length
          };
        }
      }
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped>

</style>
