<template>
    <Offer
        :back-link="backLink"
        :offer="offer"
        :traffic-types="trafficTypes">
        <template #column>
            <Permissions
                :permissions="['OFFERS.VIEW_INCLUDED_WEBMASTERS.ALL',
                               'OFFERS.VIEW_INCLUDED_WEBMASTERS.OWN']">
                <div
                    v-if="offer"
                    v-show="hasWebmasters"
                    class="column is-12">
                    <OfferWebmastersFilters :offer="offerLikeGql"></OfferWebmastersFilters>
                    <Card>
                        <OfferWebmastersTable :offer="offerLikeGql"></OfferWebmastersTable>
                    </Card>
                </div>
            </Permissions>
            <div class="column is-12">
                <div
                    v-if="!isLoading.offer"
                    class="columns is-multiline">
                    <div
                        v-if="!hasGambling"
                        class="column">
                        <OfferLandings
                            :data="transits"
                            :is-loading="isLoading.transits"
                            :is-loading-conversion="isLoading.conversion"
                            :label-language="$t('common.entity.transits.table.labels.language')"
                            :label-name="$t('common.entity.transits.table.labels.name')"
                            :label-visibility="$t('common.entity.transits.table.labels.visibility')"
                            :date="$t('common.entity.transits.table.labels.date')"
                            :offer-conversion="$t('common.entity.transits.table.labels.cr')"
                            :pagination="_transitsPagination"
                            :sticky-headers="transitsCount"
                            :title="$t('common.entity.transits.title')"
                            @infiniteHandler="infiniteHandlerTransits">
                            <template #empty>
                                {{ $t("common.entity.transits.table.empty") }}
                            </template>
                        </OfferLandings>
                    </div>
                    <div class="column">
                        <OfferLandings
                            :data="landings"
                            :sticky-headers="landingsCount"
                            :is-loading="isLoading.landings"
                            :is-loading-conversion="isLoading.conversion"
                            :label-language="$t('common.entity.landings.table.labels.language')"
                            :label-name="$t('common.entity.landings.table.labels.name')"
                            :label-visibility="$t('common.entity.landings.table.labels.visibility')"
                            :date="$t('common.entity.landings.table.labels.date')"
                            :offer-conversion="$t('common.entity.landings.table.labels.cr')"
                            :pagination="_landingsPagination"
                            :title="$t('common.entity.landings.title')"
                            @infiniteHandler="infiniteHandlerLandings">
                            <template
                                v-if="hasGambling"
                                #buttons="{ row }">
                                <LTooltip
                                    :label="$t(`webmaster.offers.table.buttons.trackingLink`)"
                                    class="flex is-align-items-center"
                                    type="is-dark"
                                    position="is-left">
                                    <b-button
                                        icon-left="link"
                                        icon-pack="fas"
                                        size="is-small"
                                        class="mx-2"
                                        type="is-info is-light"
                                        @click="openEditLinkModal(row)">
                                    </b-button>
                                </LTooltip>
                            </template>

                            <template #empty>
                                {{ $t("common.entity.landings.table.empty") }}
                            </template>
                        </OfferLandings>
                    </div>
                </div>
            </div>

            <div
                v-if="dataGender[0]"
                class="column is-12">
                <router-link
                    target="_blank"
                    :to="{
                        name: 'admin:statistics:analyticsTargetAudience',
                        query: {
                            backLink: $route.fullPath,
                            filters: JSON.stringify({ offerId: offer.id, firstPeriod })
                        }
                    }">
                    <h2 class="subtitle card-header-title pt-0 pb-4">
                        {{ $t(`common.entity.offer.analyticAudience`) }}
                        <LTooltip
                            :label="$t(`common.buttons.more`)"
                            type="is-black">
                            <b-icon
                                pack="uil"
                                icon="external-link-alt"
                                size="is-small"
                                class="ml-2">
                            </b-icon>
                        </LTooltip>
                    </h2>
                </router-link>

                <div class="columns is-multiline">
                    <div
                        :key="idx"
                        v-for="(el, idx) in dataCharts"
                        class="column is-6">
                        <AnalyticTargetAudienceDiagram
                            :title="el.titleChart"
                            :is-loading="isLoading.analyticAudience"
                            :data="el.data"
                            type="pie">
                        </AnalyticTargetAudienceDiagram>
                    </div>
                </div>
            </div>

            <OfferCallCentersModal
                v-if="!hasGambling"
                :call-centers="callCenters"
                :is-loading="isLoading.callCenters"
                @changeTab="getCallCenters($event)">
            </OfferCallCentersModal>
            <FlowsEditLinkModal
                :is-loading="isModalLoading"
                :title="$t('common.entity.landings.modal.flowsLinkModal.title')">
            </FlowsEditLinkModal>
        </template>
        <template #aside>
            <Permissions :permissions="['OFFERS.EDIT', 'OFFERS.CREATE', 'STATISTICS.SHOW_COMMISSION']">
                <div class="column is-12">
                    <BlockBox title="">
                        <template v-if="isEditOffer">
                            <router-link
                                v-if="offer"
                                :to="offerEdit">
                                <b-button
                                    class="mb-2"
                                    expanded
                                    type="is-warning">
                                    {{ $t(`admin.offer.details.aside.buttons.edit`) }}
                                </b-button>
                            </router-link>
                            <LLoading
                                v-else
                                :active="true"
                                :is-full-page="false"></LLoading>
                        </template>
                        <template v-if="isCreatedOffer">
                            <router-link
                                v-if="offer"
                                :to="{ name: 'admin:offers:create' }">
                                <b-button
                                    class="mb-2"
                                    expanded
                                    type="is-info"
                                    @click="cloneOffer">
                                    {{ $t(`admin.offer.details.aside.buttons.clone`) }}
                                </b-button>
                            </router-link>
                            <LLoading
                                v-else
                                :active="true"
                                :is-full-page="false"></LLoading>
                        </template>
                        <b-button
                            v-if="$saas.features.offerDisclaimer"
                            expanded
                            type="is-info"
                            @click="isActive = true">
                            {{ $t(`admin.offer.details.aside.buttons.rules`) }}
                        </b-button>
                    </BlockBox>
                </div>
            </Permissions>

            <OfferAsideStyleComponent
                v-slot="{ webmasterClass }"
                :has-promo="offerAsideStyleComponentOptions.hasPromo"
                :traffic-amount="offerAsideStyleComponentOptions.trafficAmount">
                <OfferConditions
                    v-if="offer"
                    class="column is-12-fullhd"
                    :has-webmaster-reward="isShowCommissions"
                    :class="webmasterClass"
                    :offer="offer">
                </OfferConditions>
            </OfferAsideStyleComponent>
        </template>
    </Offer>
</template>

<script>
  import OfferLandings from "@/components/Common/Landings/OfferLandings.vue";
  import OfferWebmastersFilters from "@/components/Admin/Offer/OfferWebmastersFilters";
  import {
    GET_CALLCENTERS,
    GET_OFFER,
    GET_OFFER_INT_ID,
    GET_OFFER_CONVERSION,
    GET_LANDINGS,
    GET_FLOW_PARAMETERS, GET_ANALYTIC_TARGET_AUDIENCE
  } from "@core/store/action-constants";
  import {
    REDEMPTION_SHARE_BY_AGE,
    REDEMPTION_SHARE_BY_GENDER,
    SET_EMPTY,
    SET_OPTIONS, SHARE_ORDERS_BY_AGE, SHARE_ORDERS_BY_GENDER,
    UPDATE_FILTERS,
    UPDATE_MODAL_ACTIVE,
    UPDATE_OFFER_LANDINGS,
    UPDATE_PAGINATION
  } from "@core/store/mutation-constants";
  import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
  import BlockBox from "@/components/Common/BlockBox";
  import Card from "@/components/Common/Card";
  import Offer from "@/components/Common/Offer/Offer";
  import OfferConditions from "@/components/Common/Offer/OfferConditions.vue";
  import OfferWebmastersTable from "@/components/Common/Offer/OfferWebmastersTable";
  import Permissions from "@/components/Common/Permissions.vue";
  import OfferAsideStyleComponent from "@/components/Common/Offer/OfferAsideStyleComponent";
  import { OfferService } from "@core/services/admin/offer/OfferService";
  import LLoading from "@/components/Common/LLoading";
  import { OfferEditService } from "@core/services/admin/offer/edit/OfferEditService";
  import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
  import FlowsEditLinkModal from "@/components/Webmaster/Flows/FlowsEditLinkModal.vue";
  import OfferCallCentersModal from "@/components/Common/Offer/OfferCallCentersModal.vue";
  import AnalyticTargetAudienceDiagram
    from "@/components/Common/Statistic/AnalyticTargetAudience/AnalyticTargetAudienceDiagram.vue";
  import { datepicker } from "@core/helpers/datepicker";
  import { offerDisclaimerModal } from "@/stores/common/offer/OfferDisclaimerModal";
  import { storeToRefs } from "pinia";
  import { useOffers } from "@/stores/admin/offers/offersStore";
  import { hasPermissionsStrict } from "@core/mixins/permissions";

  export default {
    name: "OfferWrapper",
    components: {
      AnalyticTargetAudienceDiagram,
      OfferCallCentersModal,
      FlowsEditLinkModal,
      LLoading,
      OfferAsideStyleComponent,
      OfferWebmastersFilters,
      Permissions,
      OfferWebmastersTable,
      OfferConditions,
      Offer,
      BlockBox,
      Card,
      OfferLandings
    },
    props: {
      backLink: {
        type: String,
        default: null
      }
    },

    setup () {
      const { isShowCommissions, isEditOffer } = useOffers();
      const store = offerDisclaimerModal();
      const { isActive } = storeToRefs(store);
      return { isActive, isShowCommissions, isEditOffer };
    },

    async created () {
      const { offerId, isReadyFilter: isReady, isShowCommissions } = this;

      if (offerId) {
        this.offer = await this.GET_OFFER({ service: OfferService, offerId, isShowCommissions });
      } else {
        const { slug } = this.$route.params;
        this.offer = await this.GET_OFFER_INT_ID(slug);
      }

      this.UPDATE_LANDING_FILTERS({ isReady });
      this.UPDATE_TRANSITS_FILTERS({ isReady });
      if (!this.hasGambling) {
        this.UPDATE_ANALYTIC_AUDIENCE_FILTERS({
          firstPeriod: datepicker({ amount: 45, unit: "days", amountEnd: 15, unitEnd: "days" }),
          offerId: offerId ?? this.offer.id
        });
        this.GET_ANALYTIC_TARGET_AUDIENCE();
      }

      await Promise.all([
        this.GET_OFFER_LANDINGS(offerId ?? this.offer.id),
        this.GET_OFFER_TRANSITS(offerId ?? this.offer.id)
      ]);

      await this.GET_OFFER_CONVERSION(this.offerId ?? this.offer.id);
      this.ADD_OFFER_CONVERSION_LANDINGS();
      this.ADD_OFFER_CONVERSION_TRANSITS();
    },

    data () {
      return {
        offer: null,
        isReadyFilter: true
      };
    },
    computed: {
      ...mapState("admin", {
        callCenters: ({ callCenters }) => callCenters.callCenters
      }),

      ...mapState({
        isCreatedOffer: ({ auth }) => hasPermissionsStrict(["OFFERS.CREATE", "STATISTICS.SHOW_COMMISSION"], auth.userPermissions)
      }),

      ...mapState("admin/offer", {
        trafficTypes: ({ offer }) => offer.offer?.trafficTypes,
        webmasters: state => state.webmasters.data,
        landings: (state) => state.landings.landings,
        _landingsPagination: (state) => state.landings.pagination,
        transits: (state) => state.transits.landings,
        _transitsPagination: (state) => state.transits.pagination,
        hasGambling: ({ offer }) => offer.offer?.vertical === VerticalsEnum.GAMBLING
      }),

      offerAsideStyleComponentOptions () {
        return {
          hasPromo: Boolean(this.offer?.landingUrl || this.offer?.photoUrl),
          trafficAmount: this.trafficTypes?.length ?? 0
        };
      },

      ...mapState("analyticTargetAudience", {
        firstPeriod: ({ filters }) => filters.firstPeriod
      }),

      ...mapGetters("analyticTargetAudience", {
        dataRedemptionGender: REDEMPTION_SHARE_BY_GENDER,
        dataRedemptionAge: REDEMPTION_SHARE_BY_AGE,
        dataAgePercent: SHARE_ORDERS_BY_AGE,
        dataGender: SHARE_ORDERS_BY_GENDER
      }),

      dataCharts () {
        return [
          { titleChart: "shareOrdersGender", data: this.dataGender[0]?.data },
          { titleChart: "redemptionShareGender", data: this.dataRedemptionGender[0]?.data },
          { titleChart: "shareOrdersAge", data: this.dataAgePercent[0]?.data },
          { titleChart: "redemptionShareAge", data: this.dataRedemptionAge[0]?.data }
        ];
      },

      isLoading () {
        return {
          offer: this.$wait(`admin/offer/${ GET_OFFER }`) || this.$wait(`admin/offer/${ GET_OFFER_INT_ID }`),
          landings: this.$wait(`admin/offer/landings/${ GET_LANDINGS }`),
          transits: this.$wait(`admin/offer/transits/${ GET_LANDINGS }`),
          conversion: this.$wait(`admin/offer/offerConversionList/${ GET_OFFER_CONVERSION }`),
          analyticAudience: this.$wait(`analyticTargetAudience/${ GET_ANALYTIC_TARGET_AUDIENCE }`),
          callCenters: this.$wait(`admin/${ GET_CALLCENTERS }`)
        };
      },

      offerId () {
        return this.$route.params.id;
      },

      hasWebmasters () {
        return this.webmasters?.items?.length;
      },

      userTimeZone () {
        return this.$store.getters.timezone;
      },

      landingsCount () {
        return this.landings && this.landings.count > 10;
      },

      transitsCount () {
        return this.transits && this.transits.count > 10;
      },

      isModalLoading () {
        return this.$wait(`editLinkModal/${ GET_FLOW_PARAMETERS }`);
      },

      offerEdit () {
        return { name: `admin:offers:${ !this.isShowCommissions ? "landingsForm" : "edit" }`, params: { id: this.offer.id } };
      },

      // TODO: Временный костыль, чтобы привести рестовский оффер к виду gql оффера
      offerLikeGql () {
        const { offer } = this;
        if (offer) {
          return {
            ...offer,
            finance: {
              webmasterReward: offer.webmasterReward,
              withdrawType: offer.withdrawType
            }
          };
        }
        return null;
      },

      landingsPagination: {
        get () {
          return this._landingsPagination;
        },

        set (value) {
          this.UPDATE_LANDINGS_PAGINATION(value);
        }
      },

      transitsPagination: {
        get () {
          return this._transitsPagination;
        },

        set (value) {
          this.UPDATE_TRANSITS_PAGINATION(value);
        }
      }
    },

    methods: {
      ...mapActions("admin", {
        GET_CALLCENTERS
      }),

      ...mapActions("editLinkModal", {
        UPDATE_MODAL_LINK_ACTIVE: UPDATE_MODAL_ACTIVE,
        SET_OPTIONS
      }),

      ...mapActions("analyticTargetAudience", {
        UPDATE_ANALYTIC_AUDIENCE_FILTERS: UPDATE_FILTERS,
        SET_EMPTY_ANALYTIC_AUDIENCE: SET_EMPTY,
        GET_ANALYTIC_TARGET_AUDIENCE
      }),

      ...mapActions("admin/offer/", {
        GET_OFFER_LANDINGS: `landings/${ GET_LANDINGS }`,
        GET_OFFER_TRANSITS: `transits/${ GET_LANDINGS }`,
        UPDATE_LANDING_FILTERS: `landings/${ UPDATE_FILTERS }`,
        UPDATE_TRANSITS_FILTERS: `transits/${ UPDATE_FILTERS }`,
        GET_OFFER_CONVERSION: `offerConversionList/${ GET_OFFER_CONVERSION }`,
        ADD_OFFER_CONVERSION_LANDINGS: `landings/${ UPDATE_OFFER_LANDINGS }`,
        ADD_OFFER_CONVERSION_TRANSITS: `transits/${ UPDATE_OFFER_LANDINGS }`,
        GET_OFFER,
        GET_OFFER_EDIT: `edit/${ GET_OFFER }`,
        GET_OFFER_INT_ID,
        SET_EMPTY
      }),

      ...mapMutations("admin", {
        UPDATE_LANDINGS_PAGINATION: `offer/landings/${ UPDATE_PAGINATION }`,
        UPDATE_TRANSITS_PAGINATION: `offer/transits/${ UPDATE_PAGINATION }`
      }),

      async cloneOffer (){
        await this.GET_OFFER_EDIT({ service: OfferEditService, offerId: this.offerId, isClone: true });
      },

      async getCallCenters (activeTab) {
        await this.GET_CALLCENTERS({
          filters: {
            offers: this.offerId ?? this.offer.id
          },
          limit: 100,
          offset: 0,
          timeZone: activeTab ? null : this.userTimeZone?.offset
        });
      },

      async infiniteHandlerTransits ($state) {
        this.transitsPagination = { page: this.transitsPagination.page + 1 };
        await this.GET_OFFER_TRANSITS(this.offerId ?? this.offer.id);
        $state.loaded();
      },

      async infiniteHandlerLandings ($state) {
        this.landingsPagination = { page: this.landingsPagination.page + 1 };
        await this.GET_OFFER_LANDINGS(this.offerId ?? this.offer.id);
        $state.loaded();
      },

      openEditLinkModal (options) {
        this.SET_OPTIONS(options);
        this.UPDATE_MODAL_LINK_ACTIVE(true);
      }
    },

    destroyed () {
      this.SET_EMPTY();
      this.SET_EMPTY_ANALYTIC_AUDIENCE();
    }
  };
</script>

<style scoped>

</style>
