<template>
    <BlockBox :title="$t('admin.offer.table.offerWebmasters.labels.header')">
        <div class="columns is-multiline">
            <div class="column is-3">
                <b-field :label="$t(`admin.offer.filters.webmaster`)">
                    <Select
                        v-model="webmasterId"
                        :get-data-vuex="getWebmasters"
                        :placeholder="$t(`admin.offer.filters.webmaster`)"
                        multiple
                        field="login"
                        prop="id">
                        <template #text="option">
                            <UserOutput
                                :user="option.option"
                                int-id>
                            </UserOutput>
                        </template>
                        <template #selected-option="option">
                            <UserOutput
                                :user="option.option"
                                int-id>
                            </UserOutput>
                        </template>
                    </Select>
                </b-field>
            </div>
        </div>
    </BlockBox>
</template>

<script>
  import BlockBox from "@/components/Common/BlockBox";
  import Select from "@/components/Common/Select/Select.vue";
  import UserOutput from "@/components/Common/Output/UserOutput.vue";
  import { DebounceUpdateByWatchedParams } from "@core/mixins";
  import { mapActions, mapState } from "vuex";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { StatisticFilters } from "@core/mixins/statistic/statisticFilters";
  import { UPDATE_FILTERS, UPDATE_OFFER_WEBMASTERS_FILTERS } from "@core/store/mutation-constants";
  import { GET_OFFER_WEBMASTERS } from "@core/store/action-constants";

  export default {
    name: "OfferWebmastersFilters",
    mixins: [StatisticFilters, DebounceUpdateByWatchedParams],
    components: {
      Select,
      UserOutput,
      BlockBox
    },

    props: {
      offer: {
        type: Object,
        default: null
      }
    },

    created () {
      this.UPDATE_OFFER_WEBMASTERS_FILTERS({ offerId: this.$route.params.id ?? this.offer.id });
    },

    data () {
      return {
        namespace: "admin/offer/webmasters"
      };
    },

    computed: {
      ...mapState("admin/offer/webmasters", {
        webmastersList: ({ webmastersFilters }) => webmastersFilters.data,
        updateParams: ({ pagination, filters }) => ({ pagination, filters })
      }),

      ...mapFields("admin/offer/webmasters", {
        fields: ["webmasterId"],
        base: "filters",
        action: UPDATE_FILTERS
      }),

      getOfferId () {
        return this.offer?.id;
      }
    },
    methods: {
      ...mapActions("admin/offer/webmasters", [
        GET_OFFER_WEBMASTERS,
        UPDATE_OFFER_WEBMASTERS_FILTERS
      ]),

      async getWebmasters (label = "", isLoading = false) {
        await this.getUserFilters("webmastersFilters", this.webmastersList,
                                  this.webmasterId,
                                  "webmasterId", label, isLoading);
        return this.webmastersList;
      },

      updated () {
        this.GET_OFFER_WEBMASTERS(this.getOfferId);
      }
    }
  };
</script>

<style lang="scss" scoped>
    section {
        margin-bottom: 1.5em;

        .columns {
            align-items: flex-end;
        }
    }
</style>
