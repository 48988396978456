<template>
    <div v-if="hasTrafficTypes">
        <div class="card">
            <h5 class="card-header-title has-text-weight-semibold py-3">
                {{ $t(`webmaster.offer.aside.traffic`) }}
            </h5>

            <div class="card-content pt-1">
                <b-tag
                    :key="index"
                    v-for="(trafficType, index) in trafficTypes"
                    class="mr-2 mb-2"
                    type="is-danger is-light">
                    {{ $t(`dictionaries.trafficTypes.${ trafficType.name }`) }}
                </b-tag>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "OfferTrafficTypes",
    props: {
      trafficTypes: {
        type: Array,
        default: null
      }
    },
    
    computed: {
      hasTrafficTypes () {
        return this.trafficTypes?.length;
      }
    }
  };
</script>

<style lang="scss" scoped>
    .card {
        &-header-title {
            font-size: $size-base;
        }
    }
</style>
