import Service from "@core/services/common/Service";
import { AdminOffer } from "@core/store/logic/admin/offer/AdminOffer";
import offer from "@core/services/admin/offer/graphql/offer.generator";

export class OfferService extends Service {
  public static async getOffer (offerId: string, isClone: boolean, isShowCommissions: boolean): Promise<AdminOffer> {
    try {
      const { data } = await this.api.get("", {
        params: offer(
          offerId,
          isShowCommissions
        )
      });
      return new AdminOffer(data.offer);
    } catch (e) {
      throw e;
    }

  }
}
