<template>
    <TileItem
        :item="callCenter"
        @delete="deleteCallCenter"
        @edit="editCallCenter">
        <template #header>
            <h3 class="title is-4 my-4">
                {{ callCenter.name }}
            </h3>
        </template>
        <template #content>
            <b-tabs v-model="activeTab">
                <b-tab-item
                    :key="tab"
                    v-for="(tab) in tabs"
                    :label="$t(`common.entity.callCenters.labels.tabs.${tab}`)">
                    <table>
                        <tr
                            :key="index"
                            v-for="(event, index) in getTabData(tab)">
                            <th>
                                {{ daysOfWeek[index] + ":" }}
                            </th>
                            <td>
                                <LTooltip
                                    :active="!!event.tooltipText"
                                    :label="event.tooltipText"
                                    position="is-bottom"
                                    type="is-dark">
                                    <span
                                        :class="event.class"
                                        style="white-space: pre-line">
                                        {{ event.label || event }}
                                    </span>
                                </LTooltip>
                            </td>
                        </tr>
                    </table>
                </b-tab-item>
            </b-tabs>
        </template>

        <template #footer>
            <div v-if="viewOnly"></div>
        </template>
    </TileItem>
</template>

<script>
  import TileItem from "@/components/Common/TileItem";
  import { CallCenters } from "@core/mixins";
  import moment from "moment";
  
  export default {
    name: "CallCenter",
    mixins: [CallCenters],
    components: { TileItem },
    props: {
      callCenter: {
        type: Object,
        default: null
      },
      viewOnly: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        activeTab: 0,
        tabs: ["timetable", "breaks"]
      };
    },
    computed: {
      breaks () {
        const breaks = {},
              timetable = { ...this.callCenter.timetable ?? this.callCenter.timeTables };
        for(const day in timetable) {
          // FixMe: стоит заменить reduce
          breaks[day] = timetable[day] ? timetable[day].map((time, index) => {
            if (timetable[day].length >= index + 2) {
              return {
                timeStart: moment(time.timeFinish).utcOffset(this.callCenter.timeZone),
                timeFinish: moment(timetable[day][index + 1].timeStart).utcOffset(this.callCenter.timeZone)
              };
            }

            return undefined;
          }).filter(time => time) : null;
        }
        return breaks;
      },

      daysOfWeek () {
        return [...this.$t("common.days.short").slice(1), this.$t("common.days.short")[0]];
      }

    },
    methods: {
      deleteCallCenter () {
        this.$emit("deleteCallCenter", this.callCenter.id);
      },

      editCallCenter () {
        this.$emit("edit", this.callCenter);
      },

      getDaysEvents (days) {
        const events = [];
        for (const day in days){
          if (days.hasOwnProperty(day)) {
            events.push(this.dayEvents(days[day]));
          }
        }
        return events;
      },

      getTabData (tab) {
        return tab === "breaks" ? this.getDaysEvents(this.breaks)
          : this.getDaysEvents(this.callCenter.timetable ?? this.callCenter.timeTables);
      }
    }
  };
</script>

<style lang="scss" scoped>

  th, td {
    font-size: 0.88em;
  }
  td {
    vertical-align: middle;
    padding-left: 5px;
  }

</style>
