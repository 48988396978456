<template>
    <div>
        <Card>
            <h2
                class="subtitle has-text-weight-bold mb-2"
                style="font-size: 1.15rem">
                {{ $t(`common.entity.offer.conditions.title`) }}
            </h2>

            <div>
                <div class="is-flex mb-2">
                    <p class="has-text-weight-bold mr-2">
                        {{ $t('common.entity.filters.verticals') }}:
                    </p>
                    {{ $t(`common.entity.verticals.${ offer && offer.vertical }`) }}
                </div>
                <div class="is-flex mb-2">
                    <p class="has-text-weight-bold mr-2">
                        {{ $t('common.entity.offer.countries') }}:
                    </p>
                    <Countries
                        :items="arrayOrEmpty(offer && offer.countries)"
                        compact
                        tooltip-position="is-bottom">
                    </Countries>
                </div>
                <div class="is-flex mb-2">
                    <p class="has-text-weight-bold mr-2">
                        {{ $t('common.entity.offer.offerType') }}:
                    </p>
                    <b-icon
                        :icon="iconType"
                        pack="fas"
                        size="is-small"
                        class="mr-2 mt-1">
                    </b-icon>
                    {{ $t(`common.entity.offer.offerTypes.${ offer && offer.offerType }`) }}
                </div>
                <div
                    v-if="offer && offer.hasOwnProperty('isInHouse')"
                    class="is-flex mb-2">
                    <p class="has-text-weight-bold mr-2">
                        {{ $t('common.entity.offer.inHouse.type') }}:
                    </p>
                    {{ $t(`common.entity.offer.inHouse.${ offer && offer.isInHouse }`) }}
                </div>
                <div class="is-flex mb-2">
                    <p class="has-text-weight-bold mr-2">
                        {{ $t('common.entity.offer.conditions.withdrawTypes.title') }}:
                    </p>
                    {{ formatEmptyString($t(`common.entity.withdrawType.${ offer && offer.withdrawType }`)) }}
                    <LabelWithAnnotation
                        v-if="offer && offer.vertical === 'GAMBLING'"
                        :tooltip="$t(`common.entity.withdrawType.tooltip.${ offer && offer.withdrawType }`)"
                        tooltip-position="is-top"
                        type="is-dark">
                    </LabelWithAnnotation>
                </div>
                <div
                    v-if="hasWebmasterReward"
                    class="is-flex mb-2">
                    <p class="has-text-weight-bold mr-2">
                        {{ $t('common.entity.offer.conditions.webmasterReward') }}:
                    </p>
                    {{ rewardWithCurrency(offer, 'webmasterReward') }}
                </div>
                <div
                    v-if="!hasWebmasterReward"
                    class="is-flex mb-2">
                    <p class="has-text-weight-bold mr-2">
                        {{ $t('common.entity.offer.conditions.advertiserWriteOff') }}:
                    </p>
                    {{ rewardWithCurrency(offer, role === 'advertiser' ? 'advertiserWriteOff' : 'advertiserMinimalWriteOff') }}
                </div>
                <div class="is-flex mb-2">
                    <p class="has-text-weight-bold mr-2">
                        {{ $t('common.entity.offer.conditions.hold') }}:
                    </p>
                    <LTooltip
                        :label="$t(`common.entity.offer.conditions.totalTimeHoldTooltip`)"
                        position="is-bottom">
                        <ConvertedTimeOutput :time="offer && offer.totalTimeHold"></ConvertedTimeOutput>
                    </LTooltip>
                </div>
                <div class="is-flex mb-2">
                    <p class="has-text-weight-bold mr-2">
                        {{ $t('common.entity.offer.categories') }}:
                    </p>
                    <CategoriesCompactList
                        :empty="$t(`common.components.categories.empty`)"
                        :items="arrayOrEmpty(offer && offer.categories)">
                    </CategoriesCompactList>
                </div>
            </div>
        </Card>
    </div>
</template>

<script>
  import CategoriesCompactList from "@/components/Common/Lists/CategoriesCompactList";
  import LabelWithAnnotation from "@/components/Common/Tooltip/LabelWithAnnotation";
  import ConvertedTimeOutput from "@/components/Common/Output/ConvertedTimeOutput";
  import LTooltip from "@/components/Common/Tooltip/LTooltip";
  import Countries from "@/components/Common/Lists/Countries";
  import Card from "@/components/Common/Card";
  import { formatCurrency, formatEmptyString } from "@core/filters";
  import { isArray } from "lodash";
  import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
  import { useOffers } from "@/stores/admin/offers/offersStore";
  import { mapGetters } from "vuex";

  export default {
    name: "OfferConditions",
    components: {
      CategoriesCompactList,
      LabelWithAnnotation,
      ConvertedTimeOutput,
      Countries,
      LTooltip,
      Card
    },

    props: {
      offer: {
        type: Object,
        default: null
      },
      hasWebmasterReward: {
        type: Boolean,
        default: true
      }
    },

    setup () {
      const { isShowCommissions } = useOffers();
      return { isShowCommissions };
    },

    computed: {
      ...mapGetters(["role"]),

      iconType () {
        return { "PUBLIC": "globe", "PRIVATE": "lock", "HIDDEN": "user-lock" }[this.offer?.offerType];
      },
        
      hasNutra () {
        return this.offer?.vertical === VerticalsEnum.NUTRA;
      }
    },

    methods: {
      formatEmptyString,
      formatCurrency,

      rewardWithCurrency (row, key) {
        return this.formatCurrency(row?.[key]?.value, row?.[key]?.currency);
      },

      arrayOrEmpty (items) {
        if (isArray(items)) {
          return items;
        }
        return [];
      }
    }
  };
</script>

<style scoped>

</style>
