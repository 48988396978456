import { render, staticRenderFns } from "./OfferState.vue?vue&type=template&id=1f63e7f9&scoped=true"
import script from "./OfferState.vue?vue&type=script&lang=ts"
export * from "./OfferState.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f63e7f9",
  null
  
)

export default component.exports