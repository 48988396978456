<template>
    <div class="column is-12">
        <BlockBox title="">
            <template v-if="offer">
                <div class="columns">
                    <div class="column is-3">
                        <div class="is-sticky">
                            <div v-if="hasVideoOffer">
                                <!-- * background loading -->
                                <div class="circle"></div>

                                <video
                                    class="is-block image is-256x256"
                                    width="100%"
                                    height="auto"
                                    autoplay
                                    muted
                                    playsinline
                                    preload="none"
                                    loop>
                                    <source
                                        :src="offer.logo.url"
                                        type="video/mp4">
                                </video>
                            </div>

                            <OfferLogo
                                v-else
                                :alt="offer.name"
                                :image="offer.logo"
                                :vertical="offer.vertical"
                                size="is-256x256">
                            </OfferLogo>

                            <div
                                v-if="offer.vertical === 'NUTRA'"
                                class="mt-3">
                                <b-button
                                    v-if="hasCallCenter"
                                    type="is-info"
                                    class="is-fullwidth"
                                    @click="UPDATE_MODAL_ACTIVE(true)">
                                    {{ $t('common.entity.offer.buttons.callCenter') }}
                                </b-button>

                                <div class="columns mt-1">
                                    <div class="column">
                                        <LTooltip
                                            type="is-dark"
                                            :label="promoPhotoLabel"
                                            position="is-top"
                                            style="width: 100%">
                                            <b-button
                                                :href="offer.photoUrl"
                                                tag="a"
                                                type="is-info is-light"
                                                target="_blank"
                                                class="is-fullwidth"
                                                :disabled="isPhotoUrl">
                                                <img
                                                    alt="photoUrl"
                                                    style="width: 19px; height: 19px"
                                                    src="@/assets/Offer/OfferImg.svg">
                                            </b-button>
                                        </LTooltip>
                                    </div>

                                    <div
                                        v-if="role === 'webmaster' ? !isPartnerNetwork : true"
                                        class="column">
                                        <LTooltip
                                            type="is-dark"
                                            :label="promoLandingLabel"
                                            position="is-top"
                                            style="width: 100%">
                                            <b-button
                                                :href="offer.landingUrl"
                                                tag="a"
                                                type="is-info is-light"
                                                target="_blank"
                                                class="is-fullwidth"
                                                :disabled="isLandingUrl">
                                                <img
                                                    alt="landingUrl"
                                                    style="width: 20px; height: 20px"
                                                    src="@/assets/Offer/OfferPage.svg">
                                            </b-button>
                                        </LTooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="column">
                        <ColoredStatus
                            v-if="offer.hasOwnProperty('isLaunched')"
                            :type="coloredStatus(offer.isLaunched)">
                            {{ $t(`common.entity.offer.${ coloredStatus(offer.isLaunched) }`) }}
                        </ColoredStatus>
                        <div class="content pt-0">
                            <div class="level is-marginless">
                                <div class="level-left mb-3">
                                    <h6
                                        v-if="isVisibleId"
                                        class="subtitle is-6 has-text-weight-normal has-text-grey is-marginless">
                                        <CanCopy
                                            :value="offer.id">
                                            <small>
                                                ID: {{ offer.id }}
                                            </small>
                                        </CanCopy>
                                    </h6>
                                </div>
                                <div class="level-right">
                                    <h5 class="is-5 title is-marginless has-text-right">
                                        <small class="has-text-weight-normal">
                                            {{ $t(`common.entity.offer.price.${ offer.vertical }`) }}:
                                        </small>
                                        {{ defaultFormatter(offer.landingCost) }} {{ currencySign(offer.landingCurrency) }}
                                        <small
                                            v-if="!offer.landingCost"
                                            class="has-text-weight-normal has-text-grey">
                                            {{ $t("common.entity.offer.noPrice") }}
                                        </small>
                                    </h5>
                                </div>
                            </div>
                            <h4 class="title is-4 is-inline-block mr-5 ml-2 mb-3">
                                <LTooltip
                                    v-if="iconType"
                                    type="is-dark"
                                    class="mb-2"
                                    position="is-top"
                                    :label="$t(`common.entity.offer.offerTypes.${ offer.offerType }`)">
                                    <b-icon
                                        :icon="iconType"
                                        pack="fas"
                                        size="is-small"
                                        class="mr-2"></b-icon>
                                </LTooltip>
                                {{ offer.name }}
                                <small class="has-text-grey">
                                    [{{ offer.intId }}]
                                </small>
                                <template v-if="role !== 'advertiser'">
                                    <OfferState
                                        :labels="offer.labels"
                                        size="medium">
                                    </OfferState>
                                </template>
                            </h4>
                            <div class="columns is-variable is-5">
                                <div class="column is-narrow">
                                    <div>
                                        {{ $t("common.entity.offer.date") }}
                                    </div>
                                    <div class="text has-text-weight-bold">
                                        {{ formatEmptyString(dateCreatedAt) }}
                                    </div>
                                </div>
                            </div>
                            <span
                                v-if="offer.description"
                                v-html="offer.description"
                                class="text"></span>

                            <template v-if="offer.rules">
                                <h6 class="title is-marginless is-6 mt-3">
                                    {{ $t("common.entity.offer.rules") }}
                                </h6>
                                <span
                                    v-html="offer.rules"
                                    class="text"></span>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <LLoading
                v-else
                :active="true"
                :is-full-page="false"></LLoading>
        </BlockBox>
    </div>
</template>

<script>
  import CanCopy from "@/components/Common/Tooltip/CanCopy.vue";
  import OfferState from "@/components/Common/Offer/OfferState.vue";
  import ColoredStatus from "@/components/Common/ColoredStatus";
  import BlockBox from "@/components/Common/BlockBox";
  import OfferLogo from "@/components/Common/Output/OfferLogo.vue";
  import LLoading from "@/components/Common/LLoading";
  import { currencySign, defaultFormatter, moment } from "@core/filters";
  import { formatEmptyString } from "@core/filters";
  import { mapActions, mapGetters } from "vuex";
  import { UPDATE_MODAL_ACTIVE } from "@core/store/action-constants";

  export default {
    name: "OfferCommon",
    components: {
      LLoading,
      OfferState,
      ColoredStatus,
      OfferLogo,
      BlockBox,
      CanCopy
    },
    props: {
      offer: {
        type: Object,
        default: null
      },
      hasCallCenter: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      ...mapGetters(["role", "isPartnerNetwork"]),

      isPhotoUrl () {
        return !this.offer.photoUrl;
      },

      promoPhotoLabel () {
        return !this.isPhotoUrl ? this.$t("common.entity.offer.photoUrl") : this.$t("common.entity.offer.noData");
      },

      isLandingUrl () {
        return !this.offer.landingUrl;
      },

      promoLandingLabel () {
        return !this.isLandingUrl ? this.$t("common.entity.offer.landingUrl") : this.$t("common.entity.offer.noData");
      },

      iconType () {
        const types = {
          "PUBLIC": "globe",
          "PRIVATE": "lock",
          "HIDDEN": "user-lock"
        };

        return types[this.offer.offerType];
      },

      dateCreatedAt () {
        return this.offer?.createdAt ? moment(this.offer?.createdAt, "DD.MM.YYYY") : null;
      },

      isVisibleId () {
        if (this.role === "webmaster") {
          return this.offer.webmasterJoinedState === "JOINED";
        }
        return true;
      },

      hasVideoOffer () {
        const { offer } = this;
        const regex = /(\.mp4)/gi;

        return offer.logo && regex.test(offer.logo.url);
      }
    },

    methods: {
      ...mapActions("callCentersOfferModal", [
        UPDATE_MODAL_ACTIVE
      ]),

      coloredStatus (status) {
        return status ? "is-success" : "is-danger";
      },

      currencySign,
      defaultFormatter,
      formatEmptyString
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .image {
            overflow: hidden;
            border-radius: 10px;
            border: 1px solid lightgray;
        }
    }

    .is-sticky {
        top: $navbar-height + 1.5rem;
        position: sticky;
    }

    .text {
      word-break: break-word;
        ::v-deep {
            strong {
                color: inherit
            }
          a {
            span {
              color: inherit !important;
            }
          }
        }

    }

    .circle {
        $size: 40;
        $indent: $size / 2;

        position: absolute;
        top: calc(50% - #{ $indent }px);
        left: calc(50% - #{ $indent }px);
        z-index: 0;

        width: $size + px;
        height: $size + px;
        border-radius: 50%;
        border: 4px solid #ccc;
        border-top-color: #666;
        animation: spin 600ms infinite linear;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
</style>
